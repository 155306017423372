<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col" style="height:auto;">
           <MenuLateral/>
            <div class="col-span-6 py-5" style="background:#FFF;padding:20px;">
              <ElegirFormaDePago/>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import ElegirFormaDePago from "@/components/Cuenta/PagoParteSocial/ElegirFormaDePago.vue";
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue";
import MenuLateralMobile from "@/components/MenuLateralMobile.vue";
import MenuLateral from '@/components/LateralMenu.vue';
import Footer from "@/components/Footer.vue";
import { getTokenDecoden } from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      apiToken: null,
    };
  },
  name: 'ElegirFormaDePagoView',
  components: {
    ElegirFormaDePago,
    HeaderMobil,
    MenuLateral,
    MenuLateralMobile,
    Footer
  },
   beforeMount() {
    this.checkLogin();
  },
  methods:{
     checkLogin(){
      var user = getTokenDecoden();
      if(user != null){
        if(user.obj['role'] == "administrator"){
           this.$router.push({path:"/admin/principal"});
        } else if(user.obj['role'] == "loan_analyst"){
           this.$router.push({path:"/analyst/principal"});
        }
      }
      else{
        this.$router.push({path:"/"});
      }
    }
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}


  #button_popups{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #FEB72B;
        width: 150px;
        height: 30px;
        padding-top: 0%;
    }

button {outline: none !important;}
</style>