<template>
<div class="mx-auto">
  <Loading :isLoading= "this.isLoading" />
  <div class="h-screen">
    <p class="title">¿Desea realizar el pago de su inscripción?</p>
    <br>
    <p class="description">
      El pago puede realizarse con tarjeta desde la plataforma, por medio de transferencia bancaria, o bien, realizar el pago en banco.
    </p>
    
    <div style="display:flex;justify-content:center;margin-top:60px;">
      <div class="grid grid-cols-3 gap-4 container-grid">
        <div class="col-span-3">
          <input class="button_popups" type="button" value="Pagar con cargo a tarjeta" v-on:click="payByStripe()" />
        </div>
      </div>
    </div>
    <div style="display:flex;justify-content:center;margin-top:20px;">
      <div class="grid grid-cols-3 gap-4 container-grid">
        <div class="col-span-3">
          <!--<input class="button_popups_no_background" type="button" value="Pagar en banco o por transferencia" v-on:click="reedirect('/cuenta/pago-parte-social/referencia-de-pago')" />-->
          <input class="button_popups_no_background" type="button" value="Información para pago en banco" v-on:click="reedirect('/cuenta/pago-parte-social/pago-banco-transferencia')" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>



<script>

import('@/assets/css/stylesheet.css')
import Loading from "@/components/Loading/VueLoading.vue";
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
    return {
      apiToken: "",
      personId: null,
      admissionStatus: "",
      slideIndex: 1,
      isLoading: false,
      voucherUploaded: false,
      ccapi: process.env.VUE_APP_CCAPI,
      urlWeb: process.env.VUE_APP_URLWEB,
      confirmPayment: false,
    }
  },
  name: 'PaginaPrincipal',
  props: {
    msg: String
  },
  components: {
    Loading
  },
  mounted() {
    this.loadInfo();
  },
  beforeMount() {
  },
  methods:{
    loadInfo() {
      var auth = getTokenDecoden()
      this.personId = auth.obj.person["id"];
    },
    Logout(){
      sessionStorage.clear();
      // window.location.href = "/"
      this.$router.push({path:"/"});
    },
    async payByStripe() {
      this.isLoading = true;
      await this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/enroll_payment_fee/create_payment_session_url`, {
          token_auth: sessionStorage.getItem("login"),
          success_url: this.urlWeb+"/cuenta/pago-parte-social/pago-exitoso",
          cancel_url: this.urlWeb+"/cuenta/principal"
      }, { 'Content-Type': 'application/json' })
      .then(response => {
          window.open(`${response.data.response.session_url}`, '_blank');
          this.isLoading = false;
      }).catch(error => {
          this.isLoading = false;
          Swal.fire({
            title: "Aviso",
            text: "Ocurrió un error al generar sesion de pago",
            icon: "info",
            confirmButtonColor: '#FEB72B'
          });
      });
    },
    reedirect(url) {
      // window.location.href = url;
      this.$router.push({path: url});
    }
  }
}
</script>

<style>
.button_popups {
  border-radius: 5px;
  background-color: #FEB72B;
  color: #fff;
  font-family: Roboto;
  font-weight:400;
  width: 100%;
  height: 40px;
  padding:10px;
  font-size:16px;
}

.button_popups_no_background {
  border-radius: 5px;
  border: 1px solid #222222;
  background-color: #fff;
  color: #222222;
  font-family: Roboto;
  font-weight:400;
  width: 100%;
  height: 40px;
  padding:10px;
  font-size:16px;
}

.container-grid {
  width: 100%;
}

.upload_button {
  border-radius: 5px;
  background-color: #DEA610;
  color: #fff;
  font-weight:bold;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size:14px;
}

.inputsForm {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.italic {
  font-style: italic;
}
.title {
  font-size:30px;
  font-family:Roboto;
  color: #BFD243;
  font-weight: 500;
  margin-top:0px;
  text-align:left;
}

.description {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  margin-top:0px;
  text-align:left;
}

.subtitle {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  text-align:left;
}

.subtitleValue {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 500;
  text-align: left;
}
@media only screen and (min-width: 1024px) {
  .container-grid {
    width: 40%;
  }

  .title {
    text-align:center;
  }

  .description {
    text-align:center;
  }
  .subtitle {
    text-align: center;
  }

  .subtitleValue {
    text-align: center;
  }
}

</style>